<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>

                <b-button variant="primary" @click="addModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="items" v-slot="props">
                    <div v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <b-button variant="danger" @click="removeItem(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        {{ (Number(props.formattedRow[props.column.field]) / 100).toFixed(2) }}
                    </span>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>


        <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label>Name:</label>
                    <b-form-input
                        type="text"
                        placeholder="Name"
                        v-model="addObject.name"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Price:</label>
                    <b-form-input
                        type="number"
                        placeholder="Price"
                        v-model="addObject.price"
                    />
                </b-form-group>
            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addItem">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>

    </div>
</template>
<script>

    import BasicTable from '@/views/components/BasicTable'
    import {BButton, BCard, BFormGroup, BFormInput, BModal, BOverlay} from 'bootstrap-vue'

    export default {
        components: {
            BasicTable,
            BOverlay,
            BCard,
            BButton,
            BModal,
            BFormInput,
            BFormGroup
        },
        data() {
            return {

                items: [],
                dataLoaded: false,

                columns: [
                    {
                        label: 'ID',
                        displayType: 0,
                        field: 'id',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search ID'
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'Price',
                        displayType: 1,
                        field: 'price',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Price'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 2,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addObject: {},
                addModalActive: false

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/fake_erp/items')
                loadPromise.then(function(response) {
                    thisIns.items = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addItem() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.post('/api/management/v1/fake_erp/items', {
                    name: this.addObject.name,
                    price: Number((Number(this.addObject.price) * 100).toFixed(0))
                })
                loadPromise.then(function() {
                    thisIns.$printSuccess('Item added')
                    thisIns.addModalActive = false
                    thisIns.$set(thisIns.addObject, 'name', '')
                    thisIns.$set(thisIns.addObject, 'price', null)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeItem(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/fake_erp/items/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Item removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>